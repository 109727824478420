<template>
  <div class="sign-in-footer">
    <div class="sign-in-footer__text">
      {{ $t('Don\'t have an account') }}?
    </div>

    <VLink
      to="sign-up"
      color="blue"
      :outlined="true"
    >
      <div>
        {{ $t('Register for free') }}
      </div>
    </VLink>
  </div>
</template>

<script>
import VLink from '@/components/ui/Auth/VLink'

export default {
  name: 'SignInFooter',
  components: {
    VLink,
  },

  i18n: {
    messages: {
      'en': {
        'Don\'t have an account': 'Don\'t have an account',
        'Register for free': 'Register for free',
      },
      'ru': {
        'Don\'t have an account': 'У вас ещё нет аккаунта',
        'Register for free': 'Зарегистрируйтесь бесплатно',
      },
    },
  },
}
</script>

<style lang="scss">
.sign-in-footer {
  text-align: center;
}

.sign-in-footer__text {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
  font-variation-settings: 'wght' 400;
  line-height: 19px;
  color: #A6ACBF;
}
</style>
